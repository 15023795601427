const ReferenceItem3 = () => {
   
    return (
        <div>
            <div className="reference">
                <img className="w-100" src='./img/references/pelican.jpeg' alt="team" />
            </div>
        </div>
    )
}

export default ReferenceItem3;