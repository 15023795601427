import './App.css';
import FlatDetail from "./components/FlatDetail"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from "./components/Home"
import Contact from "./components/Contact"
import About from "./components/About"
import Blog from "./components/Blog"
import BlogDetail from "./components/BlogDetail"
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";
import CarParkur from './components/CarParkur';
import { useEffect } from 'react';

function Redirector() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/anasayfa') {
      navigate('/');
    }
    if (location.pathname === '/hakkimizda') {
      navigate('/hakkımızda');
    }
    if (location.pathname === '/hizmetlerimiz-3') {
      navigate('/arac-parkuru');
    }
    if (location.pathname === '/arac-parkuru-2') {
      navigate('/arac-parkuru');
    }

  }, [location.pathname, navigate]);

  return null;
}
function App() {
  return (
    <BrowserRouter >
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/iletisim" element={<Contact />}></Route>
          <Route path="/hakkımızda" element={<About />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/arac-parkuru" element={<CarParkur />}></Route>
          <Route path="/blog/:id" element={<BlogDetail />}></Route>
          <Route path="/flat/:slug" element={<FlatDetail />}></Route>
        </Routes>
        <Redirector /> {/* Yönlendirme kontrolünü ekleyin */}
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
